import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';
import { PasswordApiService } from '../../services/password-api.service';

@Component({
  selector: 'app-request-reset-box',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgTemplateOutlet
  ],
  templateUrl: './request-reset-box.component.html',
  styleUrl: './request-reset-box.component.scss'
})
export class RequestResetBoxComponent {

  fb = inject(FormBuilder);
  passwordApiService = inject(PasswordApiService);

  submitted = false;
  showSuccessMessage = false;

  form = this.fb.group({
    email: new FormControl<string | null>(null, [Validators.required, Validators.email])
  });

  submit(): void {
    this.submitted = true;
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.passwordApiService
      .requestPasswordReset(this.form.controls.email.value as NonNullable<string>)
      .subscribe(response => this.showSuccessMessage = true);
  }

}
