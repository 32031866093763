export enum BikeProductTypeEnum {
  STANDARD_BIKE_EBIKE = 'STANDARD_BIKE_EBIKE',
  DIEBSTAHL = 'DIEBSTAHL',
  KOMPAKT_PAKET = 'KOMPAKT_PAKET',
  PREMIUM_PAKET = 'PREMIUM_PAKET'
}

export namespace BikeProductType {
  export function getTypeTranslation(bikeProductType: BikeProductTypeEnum) {
    switch (bikeProductType) {
      case BikeProductTypeEnum.DIEBSTAHL:
        return 'Diebstahldeckung';
      case BikeProductTypeEnum.KOMPAKT_PAKET:
        return 'Kompaktpaket';
      case BikeProductTypeEnum.PREMIUM_PAKET:
        return 'Premiumpaket';
      default:
        return '';
    }
  }
}
